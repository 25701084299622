import revive_payload_client_cxJBPAf6hY_r_uW6Sgaf6ZAyBvh9OOmj9ucsgGUugyI from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_26720da595f96bc4711cfdc5e9373a34/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Aom6OQWqbIlxkRuPB36ExxJaXSbzHbXN_pcw7t7dpjY from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_26720da595f96bc4711cfdc5e9373a34/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_guhAoBrAsUMVsvu1ipgklH3J3xOCZlsDMsKs0Lx5EZg from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_26720da595f96bc4711cfdc5e9373a34/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PBl_vXNz_RJdugXK2YFqd1wb99wnpd0JHtjnvAB3Fqs from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_26720da595f96bc4711cfdc5e9373a34/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kpM49Z0y10DzXUnSQrkn4HJUY97MCNLyMGX0TFJYDlM from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_26720da595f96bc4711cfdc5e9373a34/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_amiURqJE0pCDG476pV7OIC_Z1RK5cDcleiWOsfE1aiw from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_26720da595f96bc4711cfdc5e9373a34/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_0Vc5F3BODeEvMYTDF9VgXn8fP1lXtjlS3ZB3IZHhWDQ from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_26720da595f96bc4711cfdc5e9373a34/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_rk_T6lA8AWy7BM1mCviCudP1UhXh9FSjEVj3t8VppbA from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_26720da595f96bc4711cfdc5e9373a34/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/workspace/kody/hi3/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_Co2_kpxiDE3FLMUosjC25Kb9MyGmnMhKd6BOnBkt3aI from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_26720da595f96bc4711cfdc5e9373a34/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_cxJBPAf6hY_r_uW6Sgaf6ZAyBvh9OOmj9ucsgGUugyI,
  unhead_Aom6OQWqbIlxkRuPB36ExxJaXSbzHbXN_pcw7t7dpjY,
  router_guhAoBrAsUMVsvu1ipgklH3J3xOCZlsDMsKs0Lx5EZg,
  payload_client_PBl_vXNz_RJdugXK2YFqd1wb99wnpd0JHtjnvAB3Fqs,
  navigation_repaint_client_kpM49Z0y10DzXUnSQrkn4HJUY97MCNLyMGX0TFJYDlM,
  check_outdated_build_client_amiURqJE0pCDG476pV7OIC_Z1RK5cDcleiWOsfE1aiw,
  view_transitions_client_0Vc5F3BODeEvMYTDF9VgXn8fP1lXtjlS3ZB3IZHhWDQ,
  chunk_reload_client_rk_T6lA8AWy7BM1mCviCudP1UhXh9FSjEVj3t8VppbA,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_Co2_kpxiDE3FLMUosjC25Kb9MyGmnMhKd6BOnBkt3aI
]